<template>
  <v-card
    tile
    :loading="loading ? 'grey lighten-1' : false"
    :disabled="loading"
  >
    <v-toolbar elevation="1" dense>
      <v-toolbar-title>
        <span class="text-caption" v-text="'INGRESO'" />
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent="show_info = !show_info">
          <v-icon v-text="`mdi-chevron-${show_info ? 'up' : 'down'}`" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text v-if="rha && !loading && show_info">
      <v-row dense>
        <v-col cols="12" v-if="rha.discharge">
          <v-alert type="success" dense>
            <div class="text-center">
              El ingreso fue dado de alta el {{ rha.discharge }}
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Folio" :value="rha.folio" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData
            label="F. ingreso"
            :value="`${rha.admission_date} ${rha.admission_time}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData
            v-if="login.role_id != 9"
            label="Aseguradora"
            :value="rha.insurance.name"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rha.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData
            v-if="login.role_id != 9"
            label="Contratante"
            :value="rha.contractor"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rha.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData label="ID SM / Póliza" :value="rha.enrollment" />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rha.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData
            label="Asegurado"
            :value="`${rha.name} ${rha.first_surname} ${rha.second_surname}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Hospital" :value="rha.provider.trade_name" />
        </v-col>

        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rha.episode ? '' : 'orange--text text--darken-3'"
        >
          <ViewData label="Episodio" :value="rha.episode" />
        </v-col>

        <v-col cols="12" xs="12" md="3">
          <ViewData
            label="Alta hospitalaria"
            :value="rha.hospital_discharged"
          />
        </v-col>

        <v-col cols="12" v-if="login.role_id != 9">
          <ViewData
            label="CPT"
            :value="
              rha.cpt && rha.cpt.cpt
                ? rha.cpt.code + ': ' + rha.cpt.cpt
                : 'NO REGISTRADO'
            "
          />
        </v-col>

        <v-col cols="12" v-if="login.role_id != 9">
          <ViewData
            label="ICD"
            :value="
              rha.icd && rha.icd.icd
                ? rha.icd.code + ': ' + rha.icd.icd
                : 'NO REGISTRADO'
            "
          />
        </v-col>

        <v-col
          cols="12"
          xs="12"
          md="3"
          v-if="rha.admission_date_report && login.role_id < 3"
        >
          <ViewData
            label="Fecha ingreso reporte"
            :value="rha.admission_date_report"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          v-if="rha.discharge_report && login.role_id < 3"
        >
          <ViewData label="Fecha alta reporte" :value="rha.discharge_report" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { show } from "../requests/pageRequest";
import ViewData from "../components/ViewData.vue";

export default {
  components: {
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      rha_id: this.$attrs.rha_id,
      rha: null,
      show_info: true,
      loading: true,
    };
  },
  methods: {},
  mounted() {
    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;
      this.loading = false;
    });
  },
};
</script>